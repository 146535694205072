import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useWindowSize } from "../hooks"
import SEO from "../components/seo"
import Layout from "../components/layout"
import ExperienceSection from "../components/sections/experienceSection"

const Experience = () => {
  const dimensions = useWindowSize()
  const [screen, setScreen] = useState(null)
  useEffect(() => {
    if (dimensions) {
      setScreen(dimensions.windowWidth)
    }
  }, [dimensions])

  const { dataJson } = useStaticQuery(graphql`
    query FetchExperience {
      dataJson {
        resume {
          title
          company
          location
          from
          responsibilities
          to
        }
        education {
          school
          major
          location
          from
          to
        }
      }
    }
  `)

  return (
    <Layout isMobile={screen < 1200}>
      <SEO title="Experience" />
      <ExperienceSection data={dataJson} isMobile={screen < 1200} />
    </Layout>
  )
}

export default Experience
