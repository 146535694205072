import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import ContentWrapper from "../../styles/contentWrapper"
import WorkItemCard from "../workItemCard"

const StyledSection = motion.custom(styled.section`
  width: 100%;
  height: auto;
  margin: 50px auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin: 100px auto 50px;
  }
  background: ${({ theme }) => theme.colors.background};
`)

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
    .section-title {
      padding-right: 2rem;
      padding-left: 2rem;
      padding-bottom: 2rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
`
const PageContainer = styled.div`
  display: block;
  justify-content: space-between;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0;
    overflow: visible;
  }
  /* Show scrollbar if desktop and wrapper width > viewport width */
  @media (hover: hover) {
    &::-webkit-scrollbar {
      display: block;
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:horizontal {
      height: 0.8rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 0.2rem solid white;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 8px;
    }
  }
`

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const Section = styled.div`
  padding-bottom: 3rem;
`
const Heading = styled.h3`
  font-weight: 500;
  margin-bottom: 1rem;
  padding: 0 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 0;
  }
`

const ExperienceSection = ({ data, isMobile }) => {
  const [work, setWork] = useState(null)
  const workControl = useAnimation()

  useEffect(() => {
    workControl.start({ opacity: 1, y: 0 })
    setWork(data)
  }, [workControl, data])

  return (
    <StyledSection
      initial={{ opacity: 0, y: 20 }}
      animate={workControl}
      isMobile={isMobile}
    >
      <StyledContentWrapper>
        <PageContainer isMobile={isMobile}>
          <Section>
            <Heading>Experience</Heading>
            {work &&
              work.resume.map((item, i) => (
                <CardWrapper key={i}>
                  <WorkItemCard data={item} isMobile={isMobile} />
                </CardWrapper>
              ))}
          </Section>
          <Section>
            <Heading>Education</Heading>
            {work &&
              work.education.map((item, i) => (
                <CardWrapper key={i}>
                  <WorkItemCard data={item} isMobile={isMobile} />
                </CardWrapper>
              ))}
          </Section>
        </PageContainer>
      </StyledContentWrapper>
    </StyledSection>
  )
}

export default ExperienceSection
