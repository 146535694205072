import React, { useState, useEffect } from "react"
import styled from "styled-components"
import SkeletonLoader from "tiny-skeleton-loader-react"
import ContentWrapper from "../styles/contentWrapper"
import Card from "react-bootstrap/Card"
import ListGroup from "react-bootstrap/ListGroup"

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }
    .section-title {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        padding-right: 0;
        padding-left: 0;
      }
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      padding: 0;
      overflow: visible;
    }
    /* Show scrollbar if desktop and wrapper width > viewport width */
    @media (hover: hover) {
      &::-webkit-scrollbar {
        display: block;
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:horizontal {
        height: 0.8rem;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 0.2rem solid white;
        background-color: rgba(0, 0, 0, 0.5);
      }

      &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 8px;
      }
    }
  }
  .card {
    width: 16.25rem;
    height: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    margin: 2rem 1rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.16);
    border-radius: ${({ theme }) => theme.borderRadius};
    transition: box-shadow 0.3s ease-out;
    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.32);

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        margin: 2rem auto;
      }
      .category {
        color: ${({ theme }) => theme.colors.primary};
        text-transform: uppercase;
        letter-spacing: +1px;
        font-weight: 700;
      }
      .title {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
      }
      .date {
        font-size: 0.75rem;
        color: #555555;
        letter-spacing: +0.5px;
      }
    }
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1.5rem;
  margin: 1rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.16);
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: box-shadow 0.3s ease-out;
  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.32);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin: 1rem auto;
  }
  .category {
    color: ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;
    letter-spacing: +1px;
    font-weight: 700;
  }
  .title {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .date {
    font-size: 0.75rem;
    color: #555555;
    letter-spacing: +0.5px;
  }
`

const WorkItemCard = ({ data, isMobile }) => {
  const [items, setItems] = useState(null)

  useEffect(() => {
    setTimeout(() => {
      setItems(data)
    }, 1000)
  }, [data])
  return (
    <StyledContentWrapper>
      {items ? (
        <CardContainer isMobile={isMobile}>
          {items.title && <Card.Title>{items.title}</Card.Title>}
          {items.school && <Card.Title>{items.school}</Card.Title>}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {items.title && (
              <Card.Subtitle style={{ paddingBottom: "0.5rem" }}>
                {items.company} <span style={{ padding: "0 1rem" }}>|</span>
                {items.location}
              </Card.Subtitle>
            )}
            {items.school && (
              <Card.Subtitle style={{ paddingBottom: "0.5rem" }}>
                {items.location}
              </Card.Subtitle>
            )}
            <Card.Subtitle>Started: {items.from}</Card.Subtitle>
          </div>
          {items.responsibilities && (
            <ListGroup variant="flush">
              {items.responsibilities.map(item => (
                <ListGroup.Item style={{ paddingLeft: "0", paddingRight: "0" }}>
                  {item}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
          {items.major && (
            <ListGroup variant="flush">
              <ListGroup.Item style={{ paddingLeft: "0", paddingRight: "0" }}>
                Bachelor of Science in {items.major}
              </ListGroup.Item>
            </ListGroup>
          )}
        </CardContainer>
      ) : (
        <CardContainer>
          <SkeletonLoader
            background="#f2f2f2"
            height="2rem"
            width="40%"
            style={{ marginBottom: ".5rem" }}
          />
          <SkeletonLoader
            background="#f2f2f2"
            height="1.5rem"
            width="55%"
            style={{ marginBottom: "1.5rem" }}
          />
          <SkeletonLoader
            background="#f2f2f2"
            height="3.5rem"
            style={{ marginBottom: ".5rem" }}
          />
          <SkeletonLoader
            background="#f2f2f2"
            height="1.5rem"
            width="80%"
            style={{ marginBottom: ".5rem" }}
          />
          <SkeletonLoader
            background="#f2f2f2"
            height="3.5rem"
            style={{ marginBottom: ".5rem" }}
          />
          <SkeletonLoader
            background="#f2f2f2"
            height="1.5rem"
            width="65%"
            style={{ marginBottom: ".5rem" }}
          />
          <SkeletonLoader
            background="#f2f2f2"
            height="1.5rem"
            style={{ marginBottom: ".5rem" }}
          />
        </CardContainer>
      )}
    </StyledContentWrapper>
  )
}

export default WorkItemCard
